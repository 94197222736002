<template>
  <b-container class="container-content">
    <h4 class="page-header-text mb-4">{{ $t('nav.my_booking') }}</h4>
    <b-tabs content-class="mt-3" v-model="tabValue">
      <b-tab>
        <template slot="title">
          {{ $t('tab_title.upcoming') }}
          <div v-if="isRequiredPayment" class="circle-badge ml-2"></div>
        </template>
        <div v-if="bookingCalendarUpComing.length !== 0">
          <ul class="list-unstyled">
            <li
              v-for="(booking, index) in bookingCalendarUpComing"
              :key="index"
              class="my-4"
              style="cursor: pointer"
            >
              <div
                @click="viewBookingDetail(booking)"
                v-if="
                  booking.payment_status !== 'BANK_TRANSFER_REJECTED' &&
                  booking.payment_status !== 'ONLINE_BANKING_REJECTED'
                "
              >
                <BookingItem
                  @clickBooking="viewBookingDetail(booking)"
                  :booking="booking"
                  v-if="booking.payment_status !== 'BANK_TRANSFER_WAITING'"
                />
              </div>
              <BookingItem :booking="booking" v-else class="" />
            </li>
          </ul>

          <!-- <b-button
            block
            variant="link"
            v-if="!isLastPageOfUpcoming"
            @click="getMyBookingUpcoming"
            >{{ $t('button.load_more') }}</b-button
          > -->
        </div>
        <div v-else-if="isFetchingDataUpcoming">
          <template v-for="i in 8">
            <BookingItemLoader :key="i" class="mb-4" />
          </template>
        </div>
        <div v-else class="text-center py-4">
          <p v-html="$t('no_data.no_upcoming_booking')"></p>
          <b-link :to="{ name: 'Maids' }">{{ $t('button.view_all_maids') }}</b-link>
        </div>
        <div v-observe-visibility="visibilityChanged_2" class="w-100 text-center">
          <LoadingSpinner v-if="!isLastPageOfUpcoming" label="Loading..."  style="margin-top: 10px; height: 80px;"></LoadingSpinner>
        </div>
      </b-tab>

      <!-- History Tab -->
      <b-tab>
        <template slot="title">
          {{ $t('tab_title.history') }}
          <div v-if="isRequiredReview" class="circle-badge ml-2"></div>
        </template>

        <div v-if="bookingCalendarHistory.length !== 0" id="history_list" ref="history_list">
          <ul class="list-unstyled">
            <li
              v-for="(booking, index) in bookingCalendarHistory"
              :key="index"
              class="my-4"
              @click="viewBookingDetail(booking)"
              style="cursor: pointer"
            >
              <BookingItem
                :booking="booking"
                :showRatingBtn="true"
                :showRedDots ="showRedDots"
                @chooseMaidToReview="chooseMaidToReview"
              />
            </li>
          </ul>

          <!-- <b-button block variant="link" v-if="!isLastPageOfHistory" @click="getMyBookingHistory">{{
            $t('button.load_more')
          }}</b-button> -->
        </div>
        <div v-else-if="isFetchingDataHistory" class="text-center py-4">
          <template v-for="i in 8">
            <BookingItemLoader :key="i" class="mb-4" />
          </template>
        </div>
        <div v-else class="text-center py-4">
          <p v-html="$t('no_data.no_history_booking')"></p>
          <b-link :to="{ name: 'Maids' }">{{ $t('button.view_all_maids') }}</b-link>
        </div>
        <div v-observe-visibility="visibilityChanged" class="w-100 text-center">
          <LoadingSpinner v-if="!isLastPageOfHistory" label="Loading..."  style="margin-top: 10px; height: 80px;"></LoadingSpinner>
        </div>
      </b-tab>
    </b-tabs>
    <ReviewMaidModal
      :isShowModal="isShowReviewMaidModal"
      @onClose="onCloseReviewMaidModal"
      @onReviewSuccess="updateBookingItem"
      @onDeleteReview="deleteReviewItem"
      :maid="maidSelected"
      :booking="bookingMaidSelected"
      :calendarID="reviewCalendarID"
      :maidReview="maidReview"
    />
  </b-container>
</template>

<script>
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import ApiClient from '../services'
import ReviewMaidModal from '../components/Modal/ReviewMaid'
import LoadingSpinner from "../components/LoadingSpinner";
import StarRating from 'vue-star-rating'
import Constant from '../config/booking'
import BookingItem from '../components/BookingItem'
import BookingItemLoader from '../components/BookingItemLoader'
const BookingStore = createNamespacedHelpers('booking')
const AuthStore = createNamespacedHelpers('auth')
@Component({
  components: { ReviewMaidModal, StarRating, BookingItem, BookingItemLoader, LoadingSpinner },
  methods: {
    ...BookingStore.mapMutations(['setMyBookingOneTime', 'bookingFormRaw']),
  },
  computed: {
    ...AuthStore.mapState(['isLoggedIn']),
  },
})
export default class MyBooking extends Vue {
  booking_calendar_upcoming = []
  booking_calendar_history = []
  booking_calendar_rating = []
  defaultTab = 'upcoming'
  isShowReviewMaidModal = false
  maidReview = null
  maidSelected = {
    id: 0,
    user: {
      first_name: '',
      image_url: '',
      last_name: '',
    },
  }
  bookingMaidSelected = null
  reviewCalendarID = 0

  totalUpcomingPage = 0
  currentUpcomingPage = 0
  isLastPageOfUpcoming = false
  totalHistoryPage = 0
  currentHistoryPage = 0
  isLastPageOfHistory = false
  isFetchingDataUpcoming = false
  isFetchingDataHistory = false
  isRetryFetchUpcoming = false
  isRetryFetchHistory = false
  isRequiredReview = false
  isRequiredPayment = false
  showRedDots = false

  tabValue = this.$route.query.tab ? ['upcoming', 'history'].indexOf(this.$route.query.tab) : 0

  @Watch('tabValue')
  async onTabChanged(index) {
    this.defaultTab = ['upcoming', 'history'][index]
    this.tabValue = index
    if(this.tabValue == 1){
      await this.reviewReadHistory()
    }
    if(this.tabValue == 0){
      await this.getReviewNotifyPayment()
    }
    window.history.replaceState(null, null, `?tab=${this.defaultTab}`)
  }
  updateBookingItem(maid_review) {
    this.booking_calendar_history = this.booking_calendar_history.map((bookingCalendar) => {
      if (maid_review.booking_calendar_id === bookingCalendar.id) {
        return { ...bookingCalendar, maid_review }
      }
      return { ...bookingCalendar }
    })
  }

  deleteReviewItem(calendar_id) {
    this.booking_calendar_history = this.booking_calendar_history.map((bookingCalendar) => {
      if (calendar_id === bookingCalendar.id) {
        return { ...bookingCalendar, maid_review: null }
      }
      return { ...bookingCalendar }
    })
  }

  async mounted() {
    this.checkWebsiteLanguage()
    if (!this.isLoggedIn) return
    this.initTab()
    await this.reviewReadMenu()
    await this.getReviewNotifyHistory()
    await this.getReviewNotifyPayment()
    await this.fetchBooking()
    await this.$store.commit('maid/resetMaidsList')
    await this.$store.commit('maid/resetMaidHiddenList')
    await this.$store.commit('booking/resetState')
    await this.rateMaidByQueryURL()
    
    if(this.bookingCalendarHistory.length !== 0){
      let history_list = document.getElementById('history_list')
      history_list.addEventListener('scroll', (e) => {
        console.log(e)
        return e
      })
    }

  }
  async getReviewNotifyHistory() {
    try {
      const response = await ApiClient.reviewNotifyHistory()
      this.isRequiredReview = response.data.notify
    } catch (error) {
      console.log(error);
    }
  }
  async getReviewNotifyPayment() {
    try {
      const response = await ApiClient.reviewNotifyPayment()
      this.isRequiredPayment = response.data.notify
    } catch (error) {
      console.log(error);
    }
  }
  async reviewReadMenu() {
    try {
      const response = await ApiClient.reviewNotifyMenu()
      if(response.data.notify){
        await ApiClient.reviewReadMenu()
      }
    } catch (error) {
      console.log(error);
    }
  }
  async reviewReadHistory() {
    try {
      const response = await ApiClient.reviewNotifyHistory()
      this.showRedDots = response.data.notify
      if(response.data.notify){
        await ApiClient.reviewReadHistory()
        await this.getReviewNotifyHistory()
      }
    } catch (error) {
      console.log(error);
    }
  }
  @Watch('isLoggedIn')
  async onLoginStateChange(newVal) {
    if (newVal) {
      this.initTab()
      await this.reviewReadMenu()
      await this.getReviewNotifyHistory()
      await this.getReviewNotifyPayment()
      await this.fetchBooking()
      await this.$store.commit('maid/resetMaidsList')
      await this.$store.commit('booking/resetState')
      await this.rateMaidByQueryURL()
    }
  }

  checkWebsiteLanguage() {
    const { lang } = this.$route.query
    if (lang) {
      this.$i18n.locale = lang.toLowerCase()
      console.log('Set locale to', lang)
    }
  }

  initTab() {
    const urlParams = new URLSearchParams(window.location.search)
    const tab = urlParams.get('tab') || 'upcoming'
    this.defaultTab = tab
    this.tabValue = ['upcoming', 'history'].indexOf(tab)
  }

  async fetchBooking() {
    await this.getMyBookingUpcoming()
    await this.getMyBookingHistory()
  }

  async rateMaidByQueryURL() {
    const booking_calendar_id = this.$route.query['booking-calendar']
    if (!booking_calendar_id) return
    try {
      const result = await ApiClient.myBookingHistory({ booking_calendar_id })
      const booking = result.data.booking_calendar[0]
      if (booking) {
        this.chooseMaidToReview({ ...booking, maid: booking.booking.maid , raw: booking})
      }
    } catch (e) {
      console.error(e.response)
    }
  }

  chooseMaidToReview(bookingObject) {
    this.isShowReviewMaidModal = true
    this.maidSelected = { ...bookingObject.maid }
    this.reviewCalendarID = bookingObject.id
    this.bookingMaidSelected = {...bookingObject.raw}
    this.maidReview = bookingObject.maid_review
  }

  async getMyBookingUpcoming() {
    if (this.isFetchingDataUpcoming) return
    this.isFetchingDataUpcoming = true
    try {
      // const result_2 = await ApiClient.getMyBookingBankTransfer();
      // console.log("history result_2", result_2.data);
      let result = await ApiClient.myBookingUpComing({
        page: this.currentUpcomingPage + 1,
        limit: 20,
      })
      // result = result.filter((a)=>{
      //   console.log("a",a.booking);
      // })
      console.log('result', result)
      // for await (const bank_tranfer of result_2.data.booking_calendar) {
      //   console.log("history result_2", bank_tranfer);
      //   result.data.booking_calendar.push(bank_tranfer);
      // }
      // console.log("history", result.data);
      const { page_count, booking_calendar, pages } = result.data
      this.booking_calendar_upcoming = [...this.booking_calendar_upcoming, ...booking_calendar]
      this.totalUpcomingPage = pages.length
      this.currentUpcomingPage += 1
      this.isLastPageOfUpcoming = this.currentUpcomingPage >= page_count || page_count === 0
      this.getReviewNotifyPayment()
    } catch (e) {
      const { status } = e.response
      if (status === 401 && !this.isRetryFetchUpcoming) {
        this.isRetryFetchUpcoming = true
        this.booking_calendar_upcoming = []
        this.totalUpcomingPage = 0
        this.currentUpcomingPage = 0
        this.isLastPageOfUpcoming = false
        this.getMyBookingUpcoming()
      } else {
        this.$router.push({ name: 'Home' })
      }
    }
    this.isFetchingDataUpcoming = false
  }
  visibilityChanged_2(isVisible) {
    if (!isVisible) {
      return
    }
    if (!this.isLastPageOfUpcoming) {
      this.getMyBookingUpcoming()
    }
  }

  visibilityChanged(isVisible) {
    if (!isVisible) {
      return
    }
    if (!this.isLastPageOfHistory) {
      this.getMyBookingHistory()
    }
  }
  async getMyBookingHistory() {
    if (this.isFetchingDataHistory) return
    this.isFetchingDataHistory = true
    try {
      // const result_2 = await ApiClient.getMyBookingBankTransfer()
      // console.log("history result_2", result_2.data);

      const result = await ApiClient.myBookingHistory({
        page: this.currentHistoryPage + 1,
        limit: 10,
      })
      // console.log("result",result);

      const { page_count, booking_calendar, pages } = result.data

      this.booking_calendar_history = [...this.booking_calendar_history, ...booking_calendar]
      this.totalHistoryPage = pages.length
      this.currentHistoryPage += 1
      this.isLastPageOfHistory = this.currentHistoryPage === page_count || page_count === 0
    } catch (e) {
      const { status } = e.response
      if (status === 401 && !this.isRetryFetchHistory) {
        this.isRetryFetchHistory = true
        this.booking_calendar_history = []
        this.currentHistoryPage = 0
        this.totalHistoryPage = 0
        this.isLastPageOfHistory = false
        this.getMyBookingHistory()
      } else {
        this.$router.push({ name: 'Home' })
      }
    }
    this.isFetchingDataHistory = false
  }

  async onCloseReviewMaidModal() {
    this.isShowReviewMaidModal = false
    this.maidReview = null
    await this.getMyBookingRating()
  }

  async getMyBookingRating() {
    try {
      const result = await ApiClient.myBookingRating()
      if (result.status === 200) {
        this.booking_calendar_rating = result.data.booking_calendar
      }
    } catch (e) {
      console.log(e.response)
    }
  }

  viewBookingDetail(booking) {
    // console.log("booking", booking);
    if (
      booking.payment_status !== 'BANK_TRANSFER_REJECTED' &&
      booking.payment_status !== 'ONLINE_BANKING_REJECTED'
    ) {
      // console.log("booking", booking.payment_status);
      this.bookingFormRaw(booking.raw)
      if (booking.raw.booking.type === 'MULTI_PACKAGE') {
        this.$router.push({
          name:'MyBookingMultiPackage',
          params: {
            id: booking.raw.booking.id,
          },
          query: {
            callback:'MyBooking',
                tab:this.defaultTab
          },
        })
      } else {
        this.setMyBookingOneTime(booking.raw)
        this.$router.push({
          name: 'MyBookingOneTime',
          params: {id:booking.raw.booking.id},
          query: {
                callback:'MyBooking',
                tab:this.defaultTab
          },
        })
      }
    }
  }

  get bookingCalendarUpComing() {
    const bookingCalendar = this.booking_calendar_upcoming.map((booking) => {
      return {
        start_datetime: booking.start_datetime,
        id: booking.id,
        end_datetime: booking.end_datetime,
        type: booking.booking.type,
        maid: booking.booking.maid ? booking.booking.maid : this.mockMaidUser,
        icon: booking.booking.type.includes('ONE_TIME')
          ? require('../assets/images/icons/one-time-green.svg')
          : require('../assets/images/icons/multi-package-yellow.svg'),
        raw: booking,
        status: booking.status,
        services: booking.booking.services,
        payment: booking.booking.payment,
        payment_status: booking.booking.payment_status,
        notify: booking.notify,
      }
    })
    return bookingCalendar.filter((booking) => {
      // console.log("booking", booking);
      if (
        booking.payment_status === 'ONLINE_BANKING_WAITING' &&
        booking.payment_status === 'ONLINE_BANKING_REJECTED'
      ) {
        // console.log("booking", booking);
      }
      return (
        booking.payment_status === 'BANK_TRANSFER_WAITING' ||
        (booking.maid !== null &&
          booking.payment_status !== 'ONLINE_BANKING_WAITING' &&
          booking.payment_status !== 'ONLINE_BANKING_REJECTED')
      )
      // console.log("booking.payment_status", booking);
      // booking.payment_status === "BANK_TRANSFER_WAITING" &&
      // if (
      //   booking.maid == null ||
      //   !(
      //     booking.payment_status === "BANK_TRANSFER_WAITING" &&
      //     booking.payment_status !== "ONLINE_BANKING_WAITING" &&
      //     booking.payment_status !== "ONLINE_BANKING_REJECTED"
      //   )
      // ) {
      //   console.log("booking.payment_status", booking);
      // }
      // return (
      //   booking.maid !== null ||
      //   (booking.payment_status === "BANK_TRANSFER_WAITING" &&
      //     booking.payment_status !== "ONLINE_BANKING_WAITING" &&
      //     booking.payment_status !== "ONLINE_BANKING_REJECTED")
      // );
    })
  }

  get bookingCalendarHistory() {
    const bookingCalendar = this.booking_calendar_history.map((booking) => {
      return {
        start_datetime: booking.start_datetime,
        id: booking.id,
        end_datetime: booking.end_datetime,
        type: booking.booking.type,
        maid: booking.booking.maid ? booking.booking.maid : this.mockMaidUser,
        icon: booking.booking.type.includes('ONE_TIME')
          ? require('../assets/images/icons/one-time-green.svg')
          : require('../assets/images/icons/multi-package-yellow.svg'),
        status: booking.status,
        raw: booking,
        services: booking.booking.services,
        maid_review: booking.maid_review,
        payment: null,
        payment_status: booking.booking.payment_status,
      }
    })
    return bookingCalendar
  }
  handleScroll() {
    if (window.scrollY + window.innerHeight >= document.body.scrollHeight - 50) {
      this.getMyBookingHistory = [...this.bookingCalendar, ...this.getMyBookingHistory()]
    }
  }

  get bookingCalendarRating() {
    const bookingCalendarRating = this.booking_calendar_rating.map((bookingItem) => {
      const { booking, maid_review, no_of_reviews } = bookingItem
      return {
        maid: booking.maid ? booking.maid : this.mockMaidUser,
        calendar_id: bookingItem.id,
        icon: booking.type.includes('ONE_TIME')
          ? require('../assets/images/icons/one-time-green.svg')
          : require('../assets/images/icons/multi-package-yellow.svg'),
        rating: bookingItem.maid_review ? parseFloat(bookingItem.maid_review.rating) : 0,
        type: booking.type,
        status: booking.status,
        no_of_reviews,
        maid_review,
        payment: null,
      }
    })
    return bookingCalendarRating
  }

  joinServices(serviceArray) {
    return serviceArray
      .map((service) => {
        const serviceStr = service.toLowerCase()
        return serviceStr[0].toUpperCase() + serviceStr.slice(1, serviceStr.length)
      })
      .join(' and ')
  }

  get mockMaidUser() {
    const maid = Constant.MOCK_MAID_USER
    return {
      ...maid,
      user: {
        ...maid.user,
        first_name: this.$t('form_label.waiting_for_maids_confirmation'),
        last_name: ``,
      },
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/_variable';

.avatar {
  width: 64px;
  height: 64px;
  border-radius: 64px;
}
.nav-link {
  &.active {
    border-bottom-color: #005dff !important;
  }
}

.circle-badge {
  display: inline-table;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  background-color: #fe0000;
  border-radius: 50%;
}

// .nav-tabs .nav-link.active,
// .nav-tabs .nav-item.show .nav-link {
//   .circle-badge {
//     display: none;
//   }
// }
</style>
