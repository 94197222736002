<template>
  <div class="d-inline-block position-relative">
    <b-badge
      pill
      :variant="variant"
      class="font-weight-normal"
      :class="{
        'text-primary': isReview,
        'border-review': isReview,
      }"
      id="status-badge"
      v-if="status"
      >{{ status }}
      <b-img
        src="../assets/images/information-icon-white.svg"
        style="margin-top: -3px;"
        v-if="showDescription && !isReview && !isServiceCompleted"
      ></b-img>
      <b-img
        src="../assets/images/information-icon-blue.svg"
        style="margin-top: -3px;"
        v-if="showDescription && isReview"
      ></b-img>
    </b-badge>
    <div v-if="booking.notify" class="circle-badge ml-2"></div>
    <b-tooltip target="status-badge" placement="bottom" v-if="description && !isServiceCompleted">
      {{ description }}
    </b-tooltip>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import Constant, { STATUS } from "../config/booking";
import moment from "moment";
@Component({
  props: {
    booking: Object,
    showDescription: false,
    isShowTooltip: false,
  },
  mounted() {
    // console.log("this.booking.status", this.booking);
  },
})
export default class BookingStatus extends Vue {
  get isBookingMP() {
    return this.booking.type === Constant.MULTI_PACKAGE;
  }

  get isBookingOneTimeAutoSelect() {
    return this.booking.type === Constant.ONE_TIME_AUTO_SELECT;
  }

  get isBookingOneTimeManualSelect() {
    return this.booking.type === Constant.ONE_TIME_MANUAL_SELECT;
  }

  // เช็คว่าจ่ายเงินเกิน 1 วันแล้วหรือยัง ?
  // หากเกิน 1 วันแล้วไม่มีการตอบรับ ลูกค้าจะต้องเปลี่ยนวันเวลา
  get isTimeoutOfAppproval() {
    if (!this.booking.payment) return false;
    const isTimeoutOfAppproval =
      moment().diff(moment(this.booking.payment.create_datetime), "seconds") >
      60 * 60 * 24;
    return isTimeoutOfAppproval;
  }

  get hasMaid() {
    return ``;
  }

  get isNoAvailableMaid() {
    return this.booking.status === STATUS.noAvailableMaid;
  }

  get isBookingUnsuccessful() {
    // console.log("this.booking.status", this.booking.status);
    if (this.isNoAvailableMaid) return true;

    if (this.isPassed && this.booking.status !== STATUS.approved) return true;

    switch (this.booking.type) {
      case Constant.ONE_TIME_AUTO_SELECT:
        return (
          (this.booking.status === STATUS.awaiting &&
            this.isTimeoutOfAppproval) ||
          this.booking.status === STATUS.canceledByMaid
        );
      case Constant.ONE_TIME_MANUAL_SELECT:
        return this.booking.status === STATUS.canceledByMaid;
      case Constant.MULTI_PACKAGE:
        return this.booking.status === STATUS.canceledByMaid;
      default:
        return false;
    }
  }

  

  get isServiceCompleted() {
    if(!this.booking.end_datetime) return false
    let current_date = localStorage.getItem("currentDate") || new Date();
    let passed = moment(current_date) > moment(this.booking.end_datetime)
    let adminToken = window.localStorage.getItem('admin_token')
    if (adminToken && adminToken !== 'null') {
      moment() > moment(this.booking.end_datetime)
    }
    return (
      passed && this.booking.status === STATUS.approved
    )
  }

  get variant() {
    if (this.isReview) {
      return `light`;
    }
    if (this.isBookingUnsuccessful) return `danger`;
    if (this.isServiceCompleted) return `dark`;
    if (this.booking.payment_status === "BANK_TRANSFER_REJECTED") {
      return `danger`;
    }
    switch (this.booking.status) {
      case STATUS.awaiting:
        return `primary`;
      case STATUS.approved:
        return this.isBookingMP ? `warning` : `success`;
      case STATUS.canceledByCustomer:
        return `danger`;
      case STATUS.updateAwaiting:
        return `primary`;
      case STATUS.updateApproved:
        return `primary`;
      default:
        return "";
    }
  }
  get isReview() {
    if (this.booking.payment_status === "BANK_TRANSFER_REVIEWING") {
      return true;
    }
    return false;
  }

  get status() {
    // console.log(
    //   "status",
    //   this.booking,
    //   this.booking.payment_status,
    //   this.isReview
    // );
    // if (
    //   this.booking.payment_status === "ONLINE_BANKING_WAITING" &&
    //   this.booking.payment_status !== "ONLINE_BANKING_SUCCESSFUL"
    // ) {
    //   return this.$t("status.online_banking_waiting");
    // }
    if (this.booking.payment_status === "BANK_TRANSFER_REVIEWING") {
      return this.$t("status.bank_transfer_reviewing");
    }
    if (this.isBookingUnsuccessful) {
      return this.isBookingMP
        ? this.$t("status.assignment_unsuccessful")
        : this.$t("status.booking_unsuccessful");
    }
    // if (
    //   this.booking.payment_status === "ONLINE_BANKING_REJECTED" &&
    //   this.booking.payment_status !== "ONLINE_BANKING_SUCCESSFUL"
    // ) {
    //   return this.$t("status.online_banking_rejected");
    // }
    if (this.booking.payment_status === "BANK_TRANSFER_REJECTED") {
      return this.$t("status.bank_transfer_rejected");
    }

    if (this.isServiceCompleted) return this.$t("status.service_completed");

    switch (this.booking.status) {
      case STATUS.awaiting:
        return this.$t("status.payment_confirmed");
      case STATUS.approved:
        return this.$t("status.booking_confirmed");
      case STATUS.updateAwaiting:
        return this.$t("status.update_sent");
      case STATUS.updateApproved:
        return this.$t("status.update_sent");
      default:
        return "";
    }
  }

  get description() {
    if (this.booking.payment_status === "BANK_TRANSFER_REVIEWING") {
      return this.$t("status.your_bank_transfer_is_under_review");
    }
    if (!this.showDescription) return ``;
    // console.log("xxxxxxxxxxxxx");
    // if (this.booking.payment_status === "BANK_TRANSFER_REVIEWING") {
    //   return this.$t("status.your_bank_transfer_is_under_review");
    // }

    if (this.isBookingUnsuccessful) {
      if (this.isBookingMP) {
        return this.$t("status.assignment_unsuccessful");
      } else if (this.isBookingOneTimeAutoSelect || this.isNoAvailableMaid) {
        return this.$t("form_label.sorry_no_maid_available_for_auto_select");
      } else {
        return this.$t("form_label.sorry_maid_cannot_accept");
      }
    }
    switch (this.booking.status) {
      case STATUS.awaiting:
        return this.$t("status.waiting_for_maids_confirmation");
      case STATUS.approved:
        return this.$t("status.maid_has_accepted_your_booking");
      case STATUS.updateAwaiting:
        return this.$t("status.waiting_for_maids_confirmation");
      case STATUS.updateApproved:
        return this.$t("status.waiting_for_maids_confirmation");
      default:
        return "";
    }
  }

  get isPassed() {
    if(!this.booking.end_datetime) return false
    let current_date = localStorage.getItem("currentDate") || new Date();
    let passed = moment(current_date) > moment(this.booking.end_datetime);
    let adminToken = window.localStorage.getItem('admin_token')
    if (adminToken && adminToken !== 'null') {
      passed = moment() > moment(this.booking.end_datetime);
    }
    return passed
  }
}
</script>

<style lang="scss" scoped>
small {
  position: absolute;
  left: 4px;
  top: 14px;
  font-size: 13px;
  color: #757575;
}
</style>
<style lang="scss">
.border-review {
  border: #005dff 1px solid !important;
  background: none !important;
}
</style>
