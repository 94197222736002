<template>
  <div class="card p-3">
    <vue-content-loading :width="200" :height="15">
      <circle cx="6" cy="6" r="6" />
      <rect x="17" y="1" rx="1" ry="1" width="50" height="2.5" />
      <rect x="17" y="6" rx="1" ry="1" width="35" height="2.5" />
      <rect x="17" y="11" rx="1" ry="1" width="45" height="2.5" />
    </vue-content-loading>
  </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading'
export default {
  components: { VueContentLoading }
}
</script>

<style></style>
