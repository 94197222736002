<template>
  <div>
    <b-modal
      id="review-maid-modal"
      ref="review-maid"
      size="sm"
      no-close-on-backdrop
      centered
      hide-header
      hide-footer
      @hidden="onClose"
    >
      <maid-avatar class="text-center maid-avatar" size="xl" :url="maid.user.image_url" />
      <div class="review-maid-modal-container my-4 text-black px-2">
        <p
          class="text-center"
          style="font-size:18px;"
          v-html="$t('rating.please_choose_overall_rating')"
        ></p>
        <p class="text-center text-danger" v-html="choseLessThanRecommended"></p>
        <div class="rating text-center d-flex flex-column align-items-center">
          <div @click="(maidReview && maidReview.rating === 5) ? showAlertCannotCancel5() : ''">
          <star-rating
            :read-only="disableChange ||(maidReview && maidReview.rating === 5)"
            :show-rating="false"
            :padding="2"
            :star-size="32"
            v-model="form.rating"
          ></star-rating>
          </div>
          <p class="pt-3 text-warning">{{ starLabel }}</p>
        </div>
        <p class="text-center">{{ $t('rating.please_choose_reasons') }}</p>
        <b-row class="px-1">
          <b-col cols="6" md="4" v-for="(reason, index) in reasons" :key="index" class="my-1">
            <b-form-checkbox
              v-model="form.reasons[index]"
              button
              size="lg"
              button-variant="outline-dark"
              style="width:100%;"
              :disabled="disableChange"
            >
              {{ $t(`rating.${reason}`) }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="px-2">
        <b-form-textarea
          class="mt-2 mb-1"
          v-model="form.description"
          :placeholder="$t('rating.please_feel_free_to_leave_you_opinion_here')"
          rows="3"
          max-rows="6"
          :disabled="disableChange"
          maxlength="500"
        ></b-form-textarea>
        </b-row>
        <b-row class="px-3">
          <div style="text-align: right; width: 100%; color: #757575; font-size: 12px;">
          {{ form.description.length }} / 500
          </div>
        </b-row>
        <!-- <b-form-checkbox
          class="checkbox-anonymous mt-3"
          v-model="form.anonymous"
          :value="true"
          :unchecked-value="false"
          :disabled="disableChange"
        >
          {{ $t('rating.anonymous') }}
        </b-form-checkbox> -->
        <b-row class="mt-3">
          <b-col cols="6">
            <b-button variant="dark" size="lg" class="action-btn" @click="cancel" :disabled="sending" :class="{ 'gray': sending }">
              {{ $t('button.cancel') }}
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button
              variant="primary"
              size="lg"
              class="action-btn"
              @click="send"
              :disabled="form.rating === 0 || disableChange || sending"
             >
              <b-spinner
                v-if="sending"
                variant="white"
                label="Spinning"
                class="text-center spinner-border spinner-border-sm"
                style="margin-bottom: 5px;"
              ></b-spinner>
              <div v-else>
                {{ $t('button.send') }}
              </div>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-3" v-if="isUserReviewAndNot5Star">
          <b-col cols="3"></b-col>
          <b-col cols="6">
            <b-button
              variant="danger"
              size="lg"
              class="action-btn"
              @click="$refs['review-maid-delete'].show()"
             >
              <div>
                {{ $t('button.delete_rating') }}
              </div>
            </b-button>
          </b-col>
          <b-col cols="3"></b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      v-if="isUserReviewAndNot5Star"
      id="review-maid-modal"
      ref="review-maid-delete"
      size="sm"
      no-close-on-backdrop
      centered
      hide-header
      hide-footer
    >
      <div class="review-maid-modal-container my-4 px-2">
        <!-- <div class="d-flex justify-content-center">
          <i class="fas fa-times" style="font-size: 5em; color: rgb(255, 101, 101)"></i>
        </div> -->
        <p
          class="text-center mt-3 mb-4"
          v-html="$t('rating.confirm_delete_review')"
        ></p>
        <b-row class="mt-3">
          <b-col cols="2"></b-col>
          <b-col cols="4">
            <b-button
              variant="dark"
              size="sm"
              class="action-btn"
              @click="$refs['review-maid-delete'].hide()"
            >
              {{ $t('button.cancel') }}
            </b-button>
          </b-col>
          <b-col cols="4">
            <b-button variant="danger" size="sm" class="action-btn" @click="deleteReview">
              <div>
                {{ $t('button.delete') }}
              </div>
            </b-button>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
      </div>
    </b-modal>
    <base-modal id="alert-cannot-cancel-5">
      <div class="text-center">
        <p>{{ $t('error.reviews_cannot_canceled') }}</p>
      </div>
    </base-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { Component, Watch } from 'vue-property-decorator'
import MaidAvatar from '../../components/MaidAvatar'
import BaseModal from '../../components/Modal/BaseModal'
import StarRating from 'vue-star-rating'
import ApiClient from '../../services'
import moment from 'moment'

const MaidStore = createNamespacedHelpers('maid')

@Component({
  computed: {
    ...MaidStore.mapState(['modal'])
  },
  components: { MaidAvatar, StarRating, BaseModal },
  props: ['maid', 'isShowModal', 'calendarID', 'maidReview', 'booking']
})
export default class ReviewMaidModal extends Vue {
  reasons = ['TARDINESS', 'QUALITY', 'SPEED', 'SERVICE', 'COMMUNICATE', 'OTHERS']
  sending = false
  form = {
    reasons: [false, false, false, false, false, false],
    // anonymous: false,
    description: '',
    rating: 0
  }

  onClose() {
    this.resetForm()
    this.$emit('onClose')
  }

  toCapitalize(value) {
    return value[0].toUpperCase() + value.slice(1).toLowerCase()
  }

  cancel() {
    this.resetForm()
    this.$refs['review-maid'].hide()
  }

  showAlertCannotCancel5() {
    this.$root.$emit('bv::show::modal', 'alert-cannot-cancel-5')
  }

  @Watch('isShowModal')
  onToggleModal(newVal) {
    if (newVal) {
      if (this.maidReview) {
        const { anonymous, rating, description, reasons } = this.maidReview // fix me - init reason value
        const formReasons = this.reasons.map(reason => reasons.includes(reason))
        // this.form.anonymous = anonymous
        this.form.description = description
        this.form.rating = rating
        this.form.reasons = formReasons
      }
      this.$refs['review-maid'].show()
    }
  }

  get starLabel() {
    return [
      ' ',
      this.$t('rating.not_satisfied_at_all'),
      this.$t('rating.not_satisfied'),
      this.$t('rating.neutral'),
      this.$t('rating.satisfied'),
      this.$t('rating.very_satisfied')
    ][this.form.rating]
  }

  get choseLessThanRecommended() {
    if(!this.booking) return '';
    const { accommodation, duration } = this.booking.booking;

    if (!accommodation || !duration || accommodation === 'OTHERS') {
      return '';
    }

    let lessThan = '';

    const durationRecommendations = {
      CONDO_1_BR: { min: 3, max: 4 },
      CONDO_2_BR: { min: 4, max: 5 },
      CONDO_3_BR: { min: 5, max: 6 },
      CONDO_4_BR: { min: 6, max: 8 },
      HOUSE_1_STORY: { min: 3, max: 4 },
      HOUSE_2_STORIES: { min: 4, max: 6 },
      HOUSE_3_STORIES: { min: 6, max: 8 },
      OFFICE: { min: 3, max: 4 }
    };

    const recommendation = durationRecommendations[accommodation];

    if (recommendation) {
      if (duration < recommendation.min) {
        lessThan = `${recommendation.min - duration}-${recommendation.max - duration}`;
      }
    }

    return lessThan === '' ? '' : `${this.$t('rating.booked_less_than_recommended_durations_1')} ${lessThan} ${this.$t('rating.booked_less_than_recommended_durations_2')}`;
  }

  async send() {
    // fix me - use for each instead of map/filter
    this.sending = true
    const reasons = this.form.reasons
      .map((bool, index) => (bool ? this.reasons[index] : ''))
      .filter(reason => !!reason)
    const payload = { ...this.form, reasons }
    try {
      const result = await ApiClient.reviewMaid(payload, this.calendarID)
      this.$emit('onReviewSuccess', result.data)
      this.resetForm()
      this.$refs['review-maid'].hide()
    } catch (e) {
      console.log(e.response)
    }
    this.sending = false
  }

  resetForm() {
    this.form = {
      reasons: [false, false, false, false, false, false],
      // anonymous: false,
      description: '',
      rating: 0
    }
  }
  get disableChange() {
    if (!this.maidReview) return false
    // if (!this.isUserReviewAndNot5Star) return true
    return moment().diff(moment(this.maidReview.create_datetime), 'day') > 30
  }

  get isUserReviewAndNot5Star() {
    if (!this.maidReview) return false
    return this.$store.state.user && this.maidReview.user_id === this.$store.state.user.id && this.maidReview.rating < 5
  }

  async deleteReview() {
      try {
        const response = await ApiClient.deleteReview({
          id: this.maidReview.maid_id,
          review_id: this.maidReview.id,
        })
      } catch (error) {
        console.log(error)
      }
      this.resetForm()
      this.$emit('onDeleteReview', this.maidReview.booking_calendar_id)
      this.$refs['review-maid-delete'].hide()
      this.$refs['review-maid'].hide()
  }
}
</script>

<style>
label.btn.btn-outline-dark.btn-lg{
  font-size: 14px !important
}
  
</style>

<style scoped>
.gray {
  background-color: gray;
}
</style>